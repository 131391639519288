.log {
  padding: 1rem 2rem;
}
.payment-container {
  background-color: #0a1f42;
  /* desktop view
  width: 50%; */
  width: 70%;
  color: white;
  /* desktop view
  padding: 4rem; */
  padding: 2rem;
  margin: 3rem auto 3rem auto;
}

.investment {
  font-size: 1.8rem;
  font-weight: bold;
}

input {
  /* desktop view
  width: 40%; */

  width: 100%;
}

.fill {
  color: #80e142;
  margin-top: -1rem;
}

.pay {
  text-align: center;
  background-color: #0f8fe6;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 0.4rem 0.8rem;
  margin: 0;
  border-radius: 0.7rem;
  margin-top: 0.5rem;
}

.payment-text {
  color: #80e142;
}
.payment-options {
  display: flex;
  justify-content: space-between;
}
.payment-options img {
  width: 100px;
}
.Choose-Payment {
  display: none;
}

.choose-text {
  font-weight: 800;
  font-size: 1.5rem;
}

/* loader */

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  font-size: 2rem; /* Adjust the size as needed */
  color: #333; /* Adjust color as needed */
}

/* Optionally, add some animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader::before {
  content: "";
  box-sizing: border-box;
  width: 3rem;
  height: 3rem;
  border: 0.3rem solid #333;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@media only screen and (min-width: 1000px) {
  .log {
    padding: 1rem 4rem;
  }

  input {
    width: 40%;
  }

  .payment-container {
    width: 50%;
    padding: 4rem;
    margin: 5rem auto 5rem auto;
  }

  .payment-options {
    justify-content: space-around;
  }
}
