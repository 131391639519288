.s-text {
  font-size: small;
}
/* Progress bar */
.progress {
  /* padding: 1rem 4rem; destop */
  padding: 1rem 2rem;
  margin-top: 3rem;
}
.bar {
  /* width: 40%; destop*/
  width: 100%;
  height: 2rem;
  border: 2px solid gray;
  background-color: rgb(168, 168, 168);
  border-radius: 0.4rem;
}

.bar-progress {
  width: 50%;
  height: 2rem;
  background-color: rgb(4, 169, 4);
  border-radius: 0.4rem;
  animation-name: bar-progress;
  animation-duration: 4s;
  animation-delay: 4s;
}

.rate {
  /* width: 40%; destop */
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.plans {
  display: flex;
  /* direction for mobile view */
  flex-direction: column;
}

.plans-box {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 20%; destop*/
  width: 90%;
  /* margin bottom for mobile*/
  margin-bottom: 1.5rem;
  height: fit-content;
  border: 2px solid gray;
  margin-right: 2.5rem;
}

.plans-box_head {
  /* text-align: center; */
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  font-size: x-large;
}

.plans-box li {
  text-align: left;
}

/* center and .investing added to mobile view */
.center {
  text-align: center;
}

.investing {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

@keyframes bar-progress {
  from {
    width: 0%;
  }
  to {
    width: 50%;
  }
}

@media only screen and (min-width: 1000px) {
  .progress {
    padding: 1rem 4rem;
  }

  .bar,
  .rate {
    width: 40%;
  }

  .plans {
    flex-direction: row;
  }
  .plans-box {
    width: 20%;
    margin-bottom: 0;
  }
  .center {
    text-align: left;
  }
  .investing {
    margin-left: 0;
    margin-right: 0;
  }
}
