.gift {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}
.nutrition-text {
  text-align: center;
}
.gift h2 {
  color: #333;
  border-bottom: 2px solid #4caf50;
  padding-bottom: 5px;
  margin-bottom: 15px;
}

.gift label {
  display: block;
  margin-bottom: 10px;
}

.gift input[type="text"],
.gift input[type="number"],
.gift input[type="email"],
.gift textarea,
.gift select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.gift button {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: #4caf50;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.gift button:hover {
  background-color: #45a049;
}

@media only screen and (max-width: 700px) {
  .nutrition {
    padding: 0.8rem 2rem;
  }
}
