.Hero-Section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 2rem;
  /* padding: 1rem 4rem; desktop view*/
}
.reverse {
  /* width: 60%; temporary for links*/
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Hero-Section nav {
  display: none;
}
.dt-logo {
  display: none;
}
.Hero-Section img {
  object-fit: contain;
}
.Hero-Section ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.Hero-Section li {
  display: inline-block;
  padding: 1rem 1.7rem;
}
.Hero-Section div {
  display: flex;
  align-items: center;
}
.Hero-Section div a {
  text-decoration: none;
}
/* .logIn {
  color: #0f8fe6;
  margin: 0;
  font-weight: bold;
  margin-right: 2rem;
}
.signUp {
  background-color: #0f8fe6;
  color: white;
  font-weight: bold;
  padding: 0.7rem 1.2rem;
  margin: 0;
  border-radius: 0.7rem;
} */

/* Hero-Section */
.hero-section {
  height: fit-content;
  background-image: url("../Images/heroimg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1rem 2rem 6rem 2rem;
  position: relative;
}

.hero-text {
  font-family: "Montserrat Alternates", sans-serif;
  width: 75%;
  color: white;
  /* font-size: 4.5rem; destop */
  font-size: 3rem;
}

.sub-text_dt {
  display: none;
  width: 45%;
  color: white;
  margin-top: -3rem;
}
.sub-text_mv {
  color: rgb(206, 206, 206);
  margin-top: -3rem;
}

.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
}

.invest {
  text-align: center;
  background-color: #0f8fe6;
  color: white;
  font-weight: bold;
  padding: 0.4rem 0.8rem;
  margin: 0;
  border-radius: 0.7rem;
  text-decoration: none;
}
.placement {
  display: flex;
  text-decoration: none;
}
.watch {
  color: white;
  font-weight: bold;
  margin-left: 1rem;
}

.stats {
  /* width: 30%; destop*/
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 0.2rem 2rem;
  position: absolute;
  bottom: -2.5rem;
  /* margin-left: 22rem; */
  box-shadow: 2px 2px 10px black;
}

.s-text {
  font-size: small;
}

.m-text {
  font-weight: bold;
  font-size: small;
}

@media only screen and (min-width: 1000px) {
  .Hero-Section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 4rem;
  }

  .Hero-Section nav {
    display: block;
  }
  .mv-logo {
    display: none;
  }
  .dt-logo {
    display: block;
  }
  .hamb {
    display: none;
  }
  .reverse {
    width: 70%;
    flex-direction: row-reverse;
  }

  .hero-section {
    height: fit-content;
    background-image: url("../Images/heroimg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 1rem 4rem 6rem 4rem;
    position: relative;
  }
  .hero-text {
    font-size: 4.5rem;
  }
  .sub-text_mv {
    display: none;
  }
  .sub-text_dt {
    display: block;
  }
  .invest {
    padding: 0.7rem 1.2rem;
  }

  .stats {
    width: 30%;
    margin-left: 22rem;
  }
  .m-text {
    font-size: medium;
  }
}
