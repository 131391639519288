/* PaymentForm.css */
.stripe-cont {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-container {
  width: 70%;
  max-width: 500px;
  margin: 0 auto;
  padding: 2.5rem;
  background: #0a1f42;
  color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
  text-align: center;
  margin-bottom: 1em;
  font-family: "Arial", sans-serif;
}

.form-group {
  margin-bottom: 1em;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5em;
}

.form-group input {
  width: 100%;
  padding: 0.5em;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
}

.card-element {
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: white;
}

.button-container {
  text-align: center;
}

.button-container button {
  padding: 0.7em 2em;
  font-size: 1em;
  color: white;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.button-container button:disabled {
  background: #aaa;
  cursor: not-allowed;
}
