.comment {
  background-color: #0a1f42;
  color: white;
  padding: 2.5rem 2rem;
  margin-top: 4rem;
}
.review {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.review-two {
  display: none;
}
.review > img {
  width: 70px;
}
.review > p {
  font-size: medium;
  text-align: center;
  color: rgb(196, 194, 194);
}
.ratings {
  display: flex;
  flex-direction: column;
}

.ratings img {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1000px) {
  .comment {
    display: flex;
  }
  .review-two {
    display: block;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .review > p,
  .review-two > p {
    width: 50%;
  }
}
