.how {
  display: none;
}
.right-side {
  width: 30%;
  position: relative;
  margin-left: 1rem;
}
.how-title {
  text-align: left;
  /* width: 50%; */
  font-size: 1.8rem;
  font-weight: bold;
}

.how-board {
  /* width: 45%; */
  display: grid;
  grid-template-columns: auto auto;
  place-items: center;
  margin-bottom: 0.5rem;
  box-shadow: 2px 2px 5px black;
  padding: 0 0.5rem 0 0.5rem;
}
.text {
  font-size: small;
  margin-top: -0.8rem;
  /* width: 40%; */
}
.containe {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* grid-template-columns: 50% 50%;
  place-items: center; */
}
.containe > img {
  margin-top: 3.2rem;
  /* margin-right: -15rem; */
}

.how-board img {
  margin: 1rem;
}

.invest-how {
  width: fit-content;
  text-align: right;
  position: absolute;
  right: 0;
  background-color: #0f8fe6;
  color: white;
  font-weight: bold;
  padding: 0.7rem 1.2rem;
  margin: 0;
  border-radius: 0.7rem;
  text-decoration: none;
}

.board-title {
  font-weight: 600;
}

@media only screen and (min-width: 1000px) {
  .how {
    display: block;
  }
}
