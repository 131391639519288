.team-container {
  display: none;
}
.team {
  text-align: center;
  margin-top: 8rem;
}

.team h2 {
  font-size: 2rem;
  line-height: 0;
}

.grp-imgs {
  display: inline;
}
.img-cont {
  display: flex;
  justify-content: center;
}
.conta {
  display: grid;
  grid-template-columns: auto;
  place-items: center;
}

.team-cont {
  width: 80%;
  display: grid;
  grid-template-columns: auto auto auto auto;
}

.cont {
  display: flex;
  width: 100%;
  margin: 0.5rem;
}

.cont > div {
  margin-left: 1rem;
}

@media only screen and (min-width: 1000px) {
  .team-container {
    display: block;
  }
}
