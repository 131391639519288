.wrap {
  background-color: #0a1f42;
  color: white;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 0.5rem 2rem;
  margin-top: 4rem;
  border-radius: 2rem;
}

h3 {
  font-size: 1.8rem;
  line-height: 0;
}

.investTh {
  text-align: center;
  background-color: #0f8fe6;
  color: white;
  font-weight: bold;
  padding: 0.4rem 0.8rem;
  margin: 0;
  border-radius: 0.7rem;
  margin-top: 0.5rem;
}

.email {
  width: 80%;
  padding: 0.4rem 0.8rem;
  border-radius: 3rem;
}

@media only screen and (min-width: 600px) {
  .wrap {
    width: fit-content;
    padding-bottom: 1.5rem;
  }
}
