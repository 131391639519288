/* .about-w {
  line-height: 0;
  margin-bottom: -0.5rem;
} */
.why {
  display: none;
}
.contain {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
  margin-top: 2rem;
}
.s-text {
  font-size: small;
}
.number {
  line-height: 0;
  color: gray;
  font-weight: 600;
}
.stext {
  line-height: 0;
  margin-bottom: -0.1rem;
  font-weight: 600;
}
.w-invest h3 {
  line-height: 0;
  font-size: 2.5rem;
}
.plan-boxes {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 17%;
  height: fit-content;
  /* border: 2px solid gray; */
  border-top: 3px solid gray;
  margin-right: 2.5rem;
}

@media only screen and (min-width: 1000px) {
  .why {
    display: block;
  }
}
