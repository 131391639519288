.grid-container {
  /* display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  place-items: center; */
  padding: 3.5rem;
}
.icon {
  margin-right: 0.5rem;
}
.GIT,
.learn-more,
.contact {
  display: none;
}

@media only screen and (min-width: 1000px) {
  .grid-container {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    place-items: center;
    padding: 3.5rem;
  }
  .GIT,
  .learn-more,
  .contact {
    display: block;
  }
  .icon {
    margin-right: 1rem;
  }
}
