/* About */
.about {
  margin-top: 7rem;
  text-align: center;
}

.about h3 {
  line-height: 0;
  font-size: 2.5rem;
}

.about > p {
  margin-left: auto;
  margin-right: auto;
  /* width: 30%; desktop */
  width: 85%;
}

.about-w {
  line-height: 0;
  margin-bottom: -0.5rem;
}

.s-text {
  font-size: small;
}

@media only screen and (min-width: 1000px) {
  .about > p {
    width: 30%;
  }
}
