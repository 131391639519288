/* Vision */
.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 4rem;
  margin-top: 8rem;
}
.visionimg-dt {
  display: none;
  width: 550px;
}
.visionimg-mv {
  display: block;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
}
.vision {
  /* width: 30%; */
  width: 100%;
  text-align: center;
  /* margin-right: 6rem; */
}

.vision h3 {
  line-height: 0;
  font-size: 2.5rem;
}

.vision > p {
  width: 85%;
  text-align: center;
  /* margin for mobile view */
  margin-left: auto;
  margin-right: auto;
  /* text-align: left; desktop */
}

.vision-w {
  line-height: 0;
  margin-bottom: -0.5rem;
}

.s-text {
  font-size: small;
}

@media only screen and (min-width: 1000px) {
  .container {
    flex-direction: row;
  }
  .vision > p {
    width: unset;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
  }

  .vision {
    margin-right: 6rem;
    width: 30%;
  }
  .visionimg-mv {
    display: none;
  }
  .visionimg-dt {
    display: block;
  }
}
